import React, { Component } from 'react';
import BaseModal from 'shared/components/BaseModal';
import cn from 'classnames';
import { observer } from 'mobx-react';
import css from './VideoModal.scss';

class VideoModal extends Component {
  dom = {
    playLink: null,
    videoThumbnail: null,
    video: null,
    videoContainer: null
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideContentNode);
    this.play();
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleClickOutsideContentNode
    );
  }

  handleClickOutsideContentNode = event => {
    if (this.dom.contentNode && !this.dom.contentNode.contains(event.target)) {
      this.props.onClose();
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  play = () => {
    $(this.dom.videoThumbnail).hide();
    $(this.dom.videoContainer).show();
    this.dom.video.play();
  };

  render() {
    const { videoUrl, videoThumb } = this.props;
    return (
      <BaseModal isOpen modalDialogOptionalClass="modal-lg">
        <div
          className={cn('modal-content', css.wrap)}
          ref={n => {
            this.dom.contentNode = n;
          }}
        >
          <div className="modal-body">
            <button
              onClick={this.handleClose}
              type="button"
              className={cn('close', css['close-btn'])}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="ss-video">
              <div
                ref={n => {
                  this.dom.videoThumbnail = n;
                }}
              >
                <img
                  className="img-responsive"
                  src={videoThumb}
                  alt="Show Score Video"
                />
                <a
                  ref={n => {
                    this.dom.playLink = n;
                  }}
                  href="#"
                  className="ss-video__thumbnail-play-link"
                  aria-label="Play video"
                >
                  <i className="fa fa-play-circle" />
                </a>
              </div>
              <div
                ref={n => {
                  this.dom.videoContainer = n;
                }}
                className="ss-video__embed-container mb0 embed-responsive embed-responsive-16by9"
              >
                <video
                  ref={n => {
                    this.dom.video = n;
                  }}
                  controlsList="nodownload"
                  controls="controls"
                  preload="metadata"
                  className="embed-responsive-item"
                  src={videoUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
}

export default observer(VideoModal);
