import React from 'react';
import { observable, action } from 'mobx';
import cn from 'classnames';
import { observer } from 'mobx-react';
import VideoModal from 'shared/components/VideoModal';
import PlayIcon from './PlayIcon';
import css from './MembershipVideoModal.scss';

class RootStore {
  @observable isVideoModalOpen = false;

  @action.bound
  openVideoModal() {
    this.isVideoModalOpen = true;
  }

  @action.bound
  closeVideoModal() {
    this.isVideoModalOpen = false;
  }
}

const store = new RootStore();

function Memb({ videoUrl, videoThumb }) {
  return <div className={css.video}>
      <a
        className={cn(css['video-placeholder'])}
        onClick={store.openVideoModal}
        role="button"
        aria-label="Play video"
        tabIndex={0}
      >
        <div
          className={css['video-bg']}
          style={{ backgroundImage: `url(${videoThumb})` }}
        />
        <div className={css['video-overlay']} />
        <div className={css['play-button']}>
          <PlayIcon />
        </div>
      </a>

      {store.isVideoModalOpen && (
        <VideoModal
          onClose={store.closeVideoModal}
          videoUrl={videoUrl}
          videoThumb={videoThumb}
        />
      )}
    </div>
}

export default observer(Memb);
