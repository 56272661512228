import React from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import cn from 'classnames';

function BaseModal({ isOpen, children, modalDialogOptionalClass }) {
  if (!isOpen) return null;

  return createPortal(
    <div id="react-portal">
      <div className="abuse-modal modal fade in" style={{ display: 'block' }}>
        <div
          className={cn('modal-dialog', {
            [modalDialogOptionalClass]: !!modalDialogOptionalClass
          })}
        >
          {children}
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>,
    document.querySelector('body')
  );
}

export default observer(BaseModal);
